<template>
  <div class="sign-in">
    <div class="sign-left">
      <signInUpLeft />
    </div>
    <div class="sign-right">
      <div class="sign-wrap">
        <a-spin :spinning="spinning">
          <h3>Sign In By Admin</h3>
          <a-form>
            <a-form-item
              name="name"
              v-bind="validateInfos.name"
            >
              <div class="sign-input">
              <span>Account</span>
              <artmisads-input v-model:value="modelRef.name"/>
            </div>
            </a-form-item>
          </a-form>
          <a-form-item 
            v-bind="validateInfos.password"
          >
            <div class="password-input">
              <span>Password</span>
              <artmisads-input-password v-model:value="modelRef.password" style="height: 40px;"/>
            </div>
          </a-form-item>
          <div class="submit-btn">
            <a-form-item>
              <artmisads-button type="primary" html-type="submit" @click="onSignIn">Sign In</artmisads-button>
            </a-form-item>
          </div>
          <div class="bottom-text">
            This account is only available to system administrators and requires application
          </div>
          </a-spin>
        </div>
    </div>
    <contextHolder />
  </div>
</template>
<script setup>

  import { reactive, ref } from 'vue';
  import { publisherAdminApi } from '~/server/index'
  import jwt from 'jsonwebtoken';
  import { Form, notification } from 'ant-design-vue';

  import { useUserInfoStore } from '~/storeData/index';

  import signInUpLeft from '~/components/signInUpLeft.vue';

  const spinning = ref(false);
  const store = useUserInfoStore();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = description => {
    api.info({
      message: `Warning`,
      description: description,
      placement: 'topRight',
      duration: 10
    });
  };

  const useForm = Form.useForm;
  const modelRef = reactive({
    name: '',
    password: '',
  });

  const rulesRef = reactive({
    name: [{
      required: true,
      message: 'Name cannot be empty',
      trigger: ['blur']
    }],
    password: [{
      required: true,
      message: 'Password cannot be empty',
      trigger: ['blur']
    }]
  });

  const { validate, validateInfos } = useForm(modelRef, rulesRef);

  async function onSignIn () {
    const validateRes = await validate();
    if (!validateRes.errorFields) {
      spinning.value = true;
      const params = validateRes;
      const res = await publisherAdminApi.login(params);
      const { data, success, error } = res;
      if (success) {
        const userToken = data.token;
        let userInfo = jwt.decode(userToken);
        userInfo.userType = 'admin';
        store.setUserInfo(userInfo, userToken);
        location.href = '/#/operation/users';
      } else {
        openNotification(error)
      }
      spinning.value = false;
    }
  }
</script>
<style lang="less" scoped>
  .sign-in {
    width: 100dvw;
    height: 100dvh;
    min-height: 700px;
    overflow: hidden;
    display: flex;
  }
  .sign-right {
    display: flex;
    justify-content: center;
    background: var(--big-bg-1);
    flex: 1;
  }
  .sign-wrap {
    padding: 48px 40px 50px 40px;
    width: 480px;
    height: 520px;
    min-height: 520px;
    margin-top: 140px;
    background: var(--color-white);
    box-shadow: var(--section-box-shadow-2);
    border-radius: 16px;
    .to-sign-up {
      color: var(--dark-3);
      line-height: 20px;
      margin-top: 8px;
      > a {
        font-weight: 500;
        line-height: 20px;
        color: var(--link-1);
        text-decoration: underline;
      }
    }
    .password-input {
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;
      > span {
        font-weight: 500;
        color: var(--dark-2);
        line-height: 16px;
        margin-bottom: 8px;
      }
      
    }
    .sign-input {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
      > span {
        height: 16px;
        color: var(--dark-2);
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 8px;
      }
    }
    .submit-btn {
      margin-top: 32px;
      button {
        width: 100%;
      }
    }
    .bottom-text {
      margin-top: 58px;
      color: var(--dark-3);
      font-size: var(--font-size-small);
      line-height: 18px;
      text-align: left;
      > a {
        text-decoration: underline;
        color: var(--dark-3);
      }
    }
    :deep(.ant-form) {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }

</style>
